<template>
  <div class="lp" v-if="entry">
    <MatrixBlockLandingPageContent :matrix-blocks="entry.landingPageContent" v-if="entry.landingPageContent" />
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  useContext,
  useFetch,
  useMeta,
  useRoute,
  useStore,
} from '@nuxtjs/composition-api';
import { contentSectionHomepageGql } from 'GQL/pages/contentSectionHomepageGql.js';
import { log } from 'JS/global.js';
import { gqlToObject } from 'JS/seomatic.js';
import MatrixBlockLandingPageContent from 'Components/matrix_blocks/MatrixBlockLandingPageContent.vue';

export default defineComponent({
  name: 'PageContentSectionHome',
  head: {},
  components: {
    MatrixBlockLandingPageContent,
  },
  setup() {
    const { app, $craft, payload, $preview } = useContext();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      entry: null,
      seomatic: null,
    });

    useFetch(async () => {
      let data;
      if (!$preview && payload?.entry) {
        data = payload;
      } else {
        const request = await $craft({
          apiLog: 'PageContentSectionHome',
          query: contentSectionHomepageGql,
          variables: {
            site: store.state.global.localeSites[app.i18n.locale].siteHandle,
            uri: `${route.value.params.contentSection}/home`,
          },
        });

        if (request?.data) {
          data = request.data;
        }
      }

      if (data?.entry) {
        state.entry = data.entry;

        if (data.entry.seomatic) {
          state.seomatic = data.entry.seomatic;
        }
        if (state.entry?.navigationTheme) {
          store.commit('global/updateGlobalDarkTheme', true);
        } else {
          store.commit('global/updateGlobalDarkTheme', false);
        }
      }
    });

    useMeta(() => {
      return state.seomatic ? { ...gqlToObject(state.seomatic) } : {};
    });

    return { ...toRefs(state) };
  },
  mounted() {
    log('ContentSectionHome loaded');
  },
});
</script>

<style lang="scss">
.lp {
  position: relative;
}
</style>
